import styled from 'styled-components';

export const AirdropContainer = styled.div`
background: #5d58b4;
height: 1400px;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
position: absolute;
/* @media screen and (max-width: 1580px)
{
    transition: 0.8s all ease;
    height: 1000px;
} */
@media screen and (max-height: 720px)
{
    transition: 0.8s all ease;
    height: 600px;
}
@media screen and (max-width: 1280px)
{
    transition: 0.8s all ease;
    height: 700px;
}
@media screen and (max-width: 780px)
{
    transition: 0.8s all ease;
    height: 500px;
}
@media screen and (max-width: 480px)
{
    transition: 0.8s all ease;
    height: 300px;
    overflow: hidden;
    
}
`